import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './assets/css/bootstrap.rtl.min.css';
import './App.css';

import Routes from './Routes';

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-NB4LV6V2PE"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const Sidebar = lazy(() => import('./components/Sidebar/Sidebar'));

/**
 * Turns URL path into router basename by removing everything after the last slash
 * 
 * @HindleyMilner getBasename :: string -> string
 *
 * @pure
 * @param {string} path URL path, probably window.location.pathname
 * @returns {string} final basename
 * 
 * @example
 * getBaseName('/some/structure/page'); // => '/some/structure'
 */
//const getBasename = path => substr(lastIndexOf('/')(path))(0)(path);
const getBasename = path => path.substr(path.lastIndexOf('/'),0);

function App() {
	//console.log(getBasename(window.location.pathname));
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<BrowserRouter basename={getBasename(window.location.pathname)}>
				<Sidebar pageWrapId={ "page-wrap" } />
				<Routes ReactGA={ReactGA} />
			</BrowserRouter>
		</Suspense>
	);
}
export default App;
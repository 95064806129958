import React, { useEffect, lazy } from 'react';
import {Route, Routes as Switch, useLocation} from 'react-router-dom';
import GoogleAnalyticsEventTracker from './components/GoogleAnalyticsEventTracker/GoogleAnalyticsEventTracker';
 
const Listings = lazy(() => import('./pages/Listings/Listings'));
const Property = lazy(() => import('./pages/Property/Property'));
const Home = lazy(() => import('./pages/Home/Home'));
const Favourites = lazy(() => import('./pages/Favourites/Favourites'));
const Login = lazy(() => import('./pages/Login/Login'));
const Admin = lazy(() => import('./pages/Admin/Admin'));
const NotFound = lazy(() => import('./pages/NotFound/NotFound'));

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
const Routes = props => {
	const url = useLocation();
	useEffect(() => {
		document.title = url.pathname;
		props.ReactGA.pageview(url.pathname + url.search);
	}, [url.pathname]);
	const gaEventTracker = GoogleAnalyticsEventTracker('all_custom_events');
	//console.log(getBasename(window.location.pathname));
	return (
		<Switch>
			<Route path='/' element={<Home gaEventTracker={gaEventTracker.bind(this)}/>} />
			<Route path='/listings' element={<Listings gaEventTracker={gaEventTracker.bind(this)}/>} />
			<Route path='/listings/:prov' element={<Listings gaEventTracker={gaEventTracker.bind(this)}/>} />
			<Route path='/listings/:prov/:city' element={<Listings gaEventTracker={gaEventTracker.bind(this)}/>} />
			<Route path='/listings/:prov/:city/:street' element={<Listings gaEventTracker={gaEventTracker.bind(this)}/>} />
			<Route path='/rent/*' element={<Property gaEventTracker={gaEventTracker.bind(this)}/>} />
			<Route exact path='/favourites' element={<Favourites gaEventTracker={gaEventTracker.bind(this)}/>} />
			<Route exact path='/login' element={<Login gaEventTracker={gaEventTracker.bind(this)}/>} />
			<Route exact path='/admin' element={<Admin gaEventTracker={gaEventTracker.bind(this)}/>} />
			<Route element={<NotFound/>} />
		</Switch>
	);
}
export default Routes;